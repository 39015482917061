import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import CameraIcon from "../assets/camera.svg"
import ExposureIcon from "../assets/exposure.svg"
import LocationIcon from "../assets/location.svg"
import BlankIcon from "../assets/blank.svg"

const Card = styled(motion.div)`
  flex: 2;
  height: 120px;

  display: flex;
  flex-direction: column;
`

const Entry = styled.div`
  height: 20px;
  margin: 6px 0;
  display: flex;
  align-items: center;
  svg {
    height: 18px;
    width: 18px;
    .a,
    .fill {
      fill: ${props => props.theme.fontColor};
    }
  }
  span {
    margin-left: 16px;
    max-width: 80%;
  }

  @media (max-width: 700px) {
    height: 14px;
    margin: 2px 0;
    font-size: 12px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
`

const ExifCard = ({ exifData, location }) => {
  return (
    <Card
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.25, delay: 0.5 }}
    >
      <Entry>
        <LocationIcon />
        <span>{location}</span>
      </Entry>
      {exifData && (
        <>
          {exifData.camera && (
            <Entry>
              <CameraIcon />
              <span>{exifData.camera}</span>
            </Entry>
          )}
          {exifData.lens && (
            <Entry>
              <BlankIcon />
              <span>{exifData.lens}</span>
            </Entry>
          )}
          {(exifData.exposure || exifData.focalLength || exifData.iso) && (
            <Entry>
              <ExposureIcon />
              {exifData.focalLength && (
                <span>{exifData.focalLength}mm&nbsp;</span>
              )}
              {exifData.exposure && (
                <>
                  {exifData.exposure.aperture && (
                    <span>ƒ/{exifData.exposure.aperture}&nbsp;</span>
                  )}
                  {exifData.exposure.shutterSpeed && (
                    <span>{exifData.exposure.shutterSpeed}s&nbsp;</span>
                  )}
                </>
              )}
              {exifData.iso && <span>ISO&nbsp;{exifData.iso}</span>}
            </Entry>
          )}
        </>
      )}
    </Card>
  )
}

export default ExifCard
