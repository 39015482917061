import React, { useLayoutEffect, useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import Layout from "../layouts/layout"
import ExifCard from "../components/exifCard"
import { withTheme } from "@emotion/react"
import SEO from "../components/seo"

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: calc(100vh - 300px);

  img {
    margin: 0;
  }

  @media (max-width: 700px) {
    height: calc(100vh - 240px);
  }

  @media (max-width: 900px) and (max-height: 400px) {
    height: 100%;
  }
`

const ImageContainer = styled.div``

const ImageDescription = styled(motion.div)`
  flex: 3;
  padding-left: 2rem;
  p {
    margin: 0;
  }

  @media (max-width: 700px) {
    padding: 2rem 0;
    padding-left: 0;

    font-size: 14px;
  }
`

const ImageDetails = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;

  @media (max-width: 700px) {
    margin: 20px 0;
    flex-direction: column;
  }
`

function getWindowDimensions() {
  // eslint-disable-next-line no-undef
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function GalleryImage({ data, location, theme }) {
  const { title: categoryTitle } = data.galleryJson
  const [image] = data.galleryJson.images

  const headerProps = {
    sticky: false,
    collapsableOnScroll: false,
  }

  const [windowDimensions, setWindowDimensions] = useState(null)

  useLayoutEffect(() => {
    setWindowDimensions(getWindowDimensions)
  }, [])

  const aspectRatio = image.src.cloudinary.fluid.aspectRatio

  let maxWidth
  if (windowDimensions) {
    if (windowDimensions.width <= 900 && windowDimensions.height <= 400) {
      if (aspectRatio > 1) {
        maxWidth = `100%`
      } else {
        maxWidth = `calc(${aspectRatio} * (100vh - 120px))`
      }
    } else if (windowDimensions.width <= 700) {
      maxWidth = `calc(${aspectRatio} * (100vh - 240px))`
    } else {
      maxWidth = `calc(${aspectRatio} * (100vh - 300px - 6px))`
    }
  }

  const imgStyles = {
    margin: "0 auto",
    maxWidth,
    boxShadow: theme.imageBoxShadow,
  }

  return (
    <Layout {...headerProps}>
      <SEO
        description={image.description || ``}
        title={`${image.title} — ${categoryTitle}`}
        image={image.src.cloudinary.fixed.src}
        pathname={location.pathname}
      />
      <Wrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn", duration: 0.25 }}
      >
        {windowDimensions && (
          <ImageContainer tabIndex="0">
            <Img
              css={imgStyles}
              fluid={image.src.cloudinary.fluid}
              alt={image.alt || ``}
            />
          </ImageContainer>
        )}
      </Wrapper>
      <ImageDetails tabIndex="0">
        <ExifCard exifData={image.exif} location={image.location} />
        {image.description && (
          <ImageDescription
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25, delay: 0.75 }}
          >
            <p>{image.description}</p>
          </ImageDescription>
        )}
      </ImageDetails>
    </Layout>
  )
}

export default withTheme(GalleryImage)

export const query = graphql`
  query($category: String!, $name: String!) {
    galleryJson(category: { eq: $category }) {
      category
      title
      images(name: $name) {
        title
        location
        src {
          cloudinary: childCloudinaryAsset {
            fluid(maxWidth: 1300, transformations: ["q_auto:best", "f_auto"]) {
              ...CloudinaryAssetFluid
            }
            fixed(transformations: ["t_og_image", "f_jpg"]) {
              ...CloudinaryAssetFixed
            }
          }
        }
        exif {
          camera
          lens
          exposure {
            aperture
            shutterSpeed
          }
          focalLength
          iso
        }
        description
        alt
      }
    }
  }
`
